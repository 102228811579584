

const STRINGS = {

	AUTH0_USER_ID: "The Auth0 User ID corresponds to a user in Auth0.",
	AUTH0_LAST_IP: "This is the last IP address which the user had when they logged in. You can use the IP address to lookup the country which this user logs in from.",
	AUTH0_CREATED_DATE: "This is the date that the user was added to Auth0.com - not necessarily the date the Ambassador joined Tropic.",
	AUTH0_USER_ROLES: "These roles control what the user can access. By default the 'ambassador' role is added to a user. Users with 'staff' or 'admin' give the user extra privileges."

};


export default STRINGS;