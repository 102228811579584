// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ReportsScreen from 'screens/Reports';
import Auth0ReportsScreen from 'screens/Reports/Auth0';


// Layouts
import DefaultLayout from 'layouts/Default';


const ReportRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				{/* Reports landing page */}
				<Route exact path="/" element={<ReportsScreen/>}/>

				{/* Auth0 */}
				<Route exact path="/auth0" element={<Auth0ReportsScreen/>}/>

			</Routes>
		</DefaultLayout>
	);
}


export default ReportRoutes;