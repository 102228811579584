// Packages
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';


import Api from 'other/Api';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import Loading from 'components/UI/Loading';
import StatBlock from 'components/UI/StatBlock';


const Auth0ReportsScreen = () => {

	const [ stats, setStats ] = useState({});
	const [ loading, setLoading ] = useState(false);


	useEffect(() => {

		setLoading(true);

		const getStats = async () => {

			try
			{
				const response = await Api.auth0.stats();

				setStats(response.data);
				setLoading(false);
			}
			catch ( exception )
			{
				alert(exception.message);

			}
		}

		getStats();


	}, []);
	

	return (
		<>
		<ScreenTitle title="Auth0 Activity"/>

		<Accordian icon="statistics" title="Statistics" open={true}>

			{ loading &&
				<Loading message="Loading Auth0 Statistics"/>
			}

			{ ! loading &&
				<>
					<StatBlock 
						header="Active Users"
						footer="(in last 30 days)"
						value={stats.active_users_last_30days}/>
				</>
			}
			
  		</Accordian>


  		<Accordian icon="activity" title="Daily User Activity" open={true}>

  			{ stats.daily &&

  				<table className="table">
				<thead>
				<tr>
					<th>Date</th>
					<th>Logins</th>
					<th>New Users</th>
					<th>Leaked Passwords</th>
				</tr>
				</thead>
				<tbody>

				{stats.daily.map((item) => {

					return (
						<tr key={item.date}>
							<td>{dayjs(item.date).format('MMMM D YYYY')}</td>
							<td>{item.logins}</td>
							<td>{item.signups}</td>
							<td>{item.leaked_passwords}</td>
						</tr>
					);


				})}


				</tbody>

				<tfoot>

				</tfoot>

				</table>



  			}


  		</Accordian>
  		</>
	);
}


export default Auth0ReportsScreen;