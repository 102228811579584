// Libraries
import { useState } from 'react';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


import Api from 'other/Api';


const SendWelcomeEmail = props => {
	

	// Props
	const { auth0UserId, email } = props;


	// State
	const [ targetEmail, setTargetEmail ] = useState(email);
	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');


	const submit = async () => {

		if ( targetEmail === '' ) 
		{
			setErrorMessage('Enter an email address above');
			return false;
		}

		setLoading(true);
		setSuccessMessage('');
		setErrorMessage('');

		try
		{
			const response = await Api.auth0.users.sendWelcomeEmail(auth0UserId, targetEmail);

			setSuccessMessage(response.message);
		}
		catch ( exception )
		{
			setErrorMessage(exception.data.error);
		}

		setLoading(false);
	}

	return (
		<div className="SendWelcomeEmail">

			{ loading &&
				<Loading message="Sending email, please wait..."/>
			}	


			{ ! loading &&

				<form onSubmit={(e) => {
					e.preventDefault();
					submit();
				}}>

				<input type="text" value={targetEmail} onChange={(e) => {
						setTargetEmail(e.target.value);
					}} className="input" placeholder="Enter email address"/>

  				<Button style={{marginLeft: '10px'}} label="Send Email"/>

				</form>
			}

			{ successMessage !== '' &&
  				<Message type="ok" text={successMessage}/>
  			}


  			{ errorMessage !== '' &&
  				<Message type="error" text={errorMessage}/>
  			}

		</div>
	);
}


// Prop Types
SendWelcomeEmail.propTypes = {
	auth0UserId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired
}

export default SendWelcomeEmail;