// Packages
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';


// Components
import FullScreenError from 'components/UI/FullScreenError';


// Routes
import MainRoutes from 'routes/main';


// Other
import Auth from './Auth';
import Config from './config';
import Api from 'other/Api';


// Assets
import './assets/styles.css';
import './assets/styles.forms.css';


const App = () => 
{
	// State
	const [ enabled, setEnabled ] = useState(true);
	const [ message, setMessage ] = useState('');

	const navigate = useNavigate();




	useEffect(() => {

		const checkStatus = async () => {
			try
			{
				const response = await Api.apps.getServiceStatus();

				setEnabled(response.enabled);
				setMessage(response.message);
			}
			catch ( exception )
			{
				setEnabled(true);
				setMessage('');
			}

			setTimeout(() => {
				checkStatus();
			}, 60 * 1000);
		}

		checkStatus();

	}, []);


	if ( false === enabled )
	{
		return (
			<FullScreenError type="offline" title="We'll be right back" message={message}/>
		);
	}


	const onRedirectCallback = (appState) => {
		
  		navigate(appState?.returnTo || window.location.pathname);
	}; 
	
	return (
		
		<Auth0Provider
            domain={Config.AUTH0_DOMAIN}
            clientId={Config.AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            scope={Config.AUTH0_SCOPES}
            audience="https://api.admin.tropicskincare.com"
            onRedirectCallback={onRedirectCallback}
        >
			<Auth>
				<MainRoutes/>
			</Auth>
		</Auth0Provider>
	);

}

export default App;