// Libraries
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import EnvironmentSelector from 'components/EnvironmentSelector'


// Assets
import './styles.css'



const DefaultLayout = props => {

	// State
	const [ envSwitcher, setEnvSwitcher ] = useState(false);

	const {
    	user
  	} = useAuth0()


  	useEffect(() => {

  		/**	
  		 *
  		 */
  		for ( var i = 0; i < user['https://id.tropicskincare.com/roles'].length; i++ )
  		{
  			if ( user['https://id.tropicskincare.com/roles'][i] === 'staff' || user['https://id.tropicskincare.com/roles'][i] === 'admin' )
  			{
  				setEnvSwitcher(true);
  			}
  		}

  	}, [user]);
  	

	return (
		<div className="DefaultLayout">
			<Sidebar/>
			<div className="DefaultLayout__header">
				<Header/>
			</div>
			<div className="DefaultLayout__body">
				{props.children}
			</div>
			<EnvironmentSelector showInProduction={envSwitcher}/>

			
		</div>
	)
}


export default DefaultLayout