// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import ListScreen from 'screens/Users/List'
import ViewScreen from 'screens/Users/View';
import CreateScreen from 'screens/Users/Create';
import ListStaffScreen from 'screens/Users/ListStaff'
import ListAdminScreen from 'screens/Users/ListAdmin'


// Layouts
import DefaultLayout from 'layouts/Default'


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/create" element={<CreateScreen/>}/>

				<Route exact path="/staff" element={<ListStaffScreen/>}/>
				<Route exact path="/admin" element={<ListAdminScreen/>}/>


				<Route exact path="/:userId" element={<ViewScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes