/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	ENVS: {
		DEV: "https://admin.dev.tropicskincare.com",
		STAGE: "https://admin.stage.tropicskincare.com",
		PROD: "https://admin.tropicskincare.com"
	},
	STAGE: process.env.REACT_APP_STAGE,
	AUTH0_SCOPES: "openid profile email"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.admin.tropicskincare.com",
		AUTH0_DOMAIN: "id.tropicskincare.com",
		AUTH0_CLIENT_ID: "PCTU98lwFaE0m5FyLuo838dPHjKYIjBx",

		APPSTATUS_URL: 'https://appstatus.admin.tropicskincare.com/admin-portal.json'
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
		API_URL: "https://api.admin.stage.tropicskincare.com",
		AUTH0_DOMAIN: "id.stage.tropicskincare.com",
		AUTH0_CLIENT_ID: "ucMXxOsRHM3ABjylP9ELXMH8Dyi1vDHj",

		APPSTATUS_URL: 'https://appstatus.admin.stage.tropicskincare.com/admin-portal.json'
	},


	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "https://api.admin.dev.tropicskincare.com",
		AUTH0_DOMAIN: "id.dev.tropicskincare.com",
		AUTH0_CLIENT_ID: "APRHLXd1EeupRGas9pCt7OpcYKBBEi7D",

		APPSTATUS_URL: 'https://appstatus.admin.dev.tropicskincare.com/admin-portal.json'
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config