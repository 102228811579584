// Libraries
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const Message = props => {

	// Props
	const { type, text } = props;


	return (
		<div className={`Message Message--type-${type}`}>
			<div className="Message__body">
				<div className="Message__icon"></div>
				<div className="Message__text">{text}</div>
			</div>
		</div>
	);
}


// Prop Types
Message.propTypes = {
	text: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['error', 'ok'])
}


export default Message;