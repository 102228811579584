
// Assets
import './assets/styles.css';


const Tooltip = props => {
	
	const { help } = props;

	return (
		<div className="Tooltip">
			<div className="Tooltip__text">
				{help}
				<div className="Tooltip__text__arrow"></div>
			</div>
			<div className="Tooltip__handle"></div>
		</div>
	);
}

export default Tooltip;