
// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import TileIcon from 'components/UI/TileIcon';


const Screen = () => {

	return (
		<>
			<ScreenTitle title="Settings"/>

			<Accordian icon="control" title="Tropic Software Apps" open={true}>

				<TileIcon 
					icon="traffic-lights" 
					label="App Status" 
					colour="orange" 
					url="/settings/tropic-software/status"
				/>

			</Accordian>
  		</>
	)
}

export default Screen;