// Packages
import { useState, useEffect } from 'react';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';


const AppStatus = props => {

	// Props
	const { name } = props;


	// State
	const [ enabled, setEnabled ] = useState(false);
	const [ message, setMessage ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ successMessage, setSuccessMessage ] = useState('');
	const [ errorMessage, setErrorMessage ] = useState('');


	useEffect(() => {

		const getStatus = async () => {

			setLoading(true);

			try
			{
				const response = await Api.apps.getStatus(name);

				setEnabled(response.data.enabled === 'true' || response.data.enabled === true);
				setMessage(response.data.message);

				setLoading(false);
			}
			catch ( exception )
			{
				setErrorMessage(exception.error);
				setLoading(false);
			}
		}

		getStatus();

	}, [name]);



	const onSubmit = async (e) => {

		e.preventDefault();

		setLoading(true);

		try
		{
			await Api.apps.setStatus(name, enabled, message);

			setSuccessMessage('App status has been saved');
		}
		catch ( exception )
		{
			setErrorMessage(exception.error);
		}

		setLoading(false);

	}




	if ( true === loading )
	{
		return <Loading message="Loading, please wait"/>
	}
	

	return (
		<div className="AppStatus">

			{ successMessage !== '' &&
  				<Message type="ok" text={successMessage}/>
  			}

  			{ errorMessage !== '' &&
  				<Message type="error" text={errorMessage}/>
  			}

			<form className="form" onSubmit={onSubmit}>

				<div className="form__field">
					<select onChange={(e) => {

						setEnabled('true' === e.target.value);

					}} value={enabled} className="form__field__input">
						<option value={true}>Enabled</option>
						<option value={false}>Disabled</option>

					</select>
				</div>	


				{ false === enabled &&

					<div className="form__field">

						<textarea onChange={(e) => {
							setMessage(e.target.value);
						}} value={message} className="form__field__input AppStatus__textarea" placeholder="Offline Message"/>

					</div>
				}


				<div className="form__footer">
					<Button label="Save App Status" className="button"/>
				</div>

			</form>

		</div>
	);

}

export default AppStatus;