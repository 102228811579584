// Packages
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const ModalWindow = props => {

	const { onClose } = props;

	return (
		<div className="ModalWindow" onClick={(e) => {
			e.stopPropagation();
		}}>

			<div className="ModalWindow__header">
				<div className="ModalWindow__close" onClick={onClose}/>
			</div>

			<div className="ModalWindow__body">
				{props.children}
			</div>

		</div>
	);
}

ModalWindow.propTypes = {
	onClose: PropTypes.func.isRequired
}

export default ModalWindow;