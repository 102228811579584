// Packages
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


// Components
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


import Api from 'other/Api';


const ListUsersByRole = props => {

	// Props
	const { roleName } = props;


	// State
	const [ users, setUsers ] = useState([]);
	const [ pageNumber, setPageNumber ] = useState(0);
	const [ meta, setMeta ] = useState({});


	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState('');
	


	const getUsers = async () => {

		setLoading(true);
		setErrorMessage('');

		try
		{
			const response = await Api.auth0.roles.getUsers(roleName, {
				page: pageNumber
			});

			setUsers(response.data.users);

			setMeta({
				total: response.data.total,
				start: response.data.start,
				limit: response.data.limit
			});
		}
		catch ( exception )
		{
			setErrorMessage(exception.response.error);
		}

		setLoading(false);
	}



	useEffect(() => {

		
			getUsers();

		// eslint-disable-next-line
	}, [pageNumber]);



	return (
		<>

		{ errorMessage !== '' &&
			<Message type="error" text={errorMessage}/>
		}


		{ loading &&
  			<Loading style={{marginTop: '25px'}} message={`Loading ${roleName} users from Auth0`}/>
  		}


  		{ ! loading && users.length &&
  			<>
  			<div className="table-caption">
  				There are <b>{meta.total}</b> {roleName} users
  			</div>
			
			<table className="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Email</th>
			</tr>
			</thead>
			<tbody>
			
			{users.map((user) => {


				return (
					<tr key={user.user_id}>
						<td><Link to={`/users/${user.user_id}`}><b>{user.name}</b></Link></td>
						<td>{user.email}</td>
					</tr>
				);

			})}

			</tbody>
			</table>
			</>
		}

		<div className="pagination">
			{ pageNumber > 0 &&
				<div onClick={() => {
					setPageNumber(pageNumber-1);
				}} className="pagination">
					&larr; Previous Page
				</div>
			}
			{ pageNumber === 0 &&
				<div></div>
			}

			{ meta.start + meta.length < meta.total &&
				<div onClick={() => {
					setPageNumber(pageNumber+1);
				}} className="pagination">
					Next Page &rarr;
				</div>
			}
		</div>

		</>

	);


}


export default ListUsersByRole;