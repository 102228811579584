
// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import AppStatus from 'components/AppStatus';


const Screen = () => {

	return (
		<>
			<ScreenTitle breadcrumb={[
				{label: 'Settings', url: '/settings'}
			]} title="App Status"/>

			<p>This page allows you to take Ambassador software offline - to stop them accessing it. 
			When you make it a change, it can take up to 60 seconds before it's seen by Ambassadors.</p>

			<Accordian icon="app" title="Ambassador Access">
				<AppStatus name="ambassador-access"/>
			</Accordian>

			<Accordian icon="app" title="Admin Portal">
				<AppStatus name="admin-portal"/>	
			</Accordian>
  		</>
	)
}

export default Screen;