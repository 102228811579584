// Libraries
import { useState } from 'react';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';

import Api from 'other/Api';


// Assets
import './styles.css';


const ChangePassword = props => {
	

	// Props
	const { auth0UserId } = props;


	// State
	const [ password, setPassword ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');



	const submit = async () => {

		if ( password === '' ) 
		{
			setErrorMessage('Enter a password above');
			return false;
		}

		setLoading(true);
		setSuccessMessage('');
		setErrorMessage('');

		try
		{
			await Api.auth0.users.setPassword(auth0UserId, password);

			setSuccessMessage('Password changed to ' + password);
			setPassword('');
		}
		catch ( exception )
		{
			setErrorMessage(exception.data.error);
		}

		setLoading(false);
	}


	const suggestPassword = () => {

		const words = [
			'Love', 'Glow', 'Tropic', 'Yellow', 'Red', 'Purple', 'Tree', 
			'Leaf', 'Green', 'Orange', 'Blue', 'Spark', 'Jump', 'Joy',
			'Natural', 'Smooth', 'Empower', 'Tree', 'Forrest'
		];

		const number = Math.floor(Math.random() * 101);
		const word1 = words[Math.floor(Math.random()*(words.length-1))];
		const word2 = words[Math.floor(Math.random()*(words.length-1))];

		setPassword(word1 + number + word2);
	}


	return (
		<div className="ChangePassword">

			{ loading &&
				<Loading message="Setting password, please wait..."/>
			}


			{ ! loading &&

				<form onSubmit={(e) => {
					e.preventDefault();
					submit();
				}}>

					<input type="text" value={password} onChange={(e) => {
							setPassword(e.target.value);
					}} className="input" placeholder="Enter new password"/>

					<Button style={{marginLeft: '10px'}} label="Set Password"/>

					<div className="ChangePassword__suggest" onClick={suggestPassword}>
						Suggest Password
					</div>

				</form>
			}


  			{ successMessage !== '' &&
  				<Message type="ok" text={successMessage}/>
  			}


  			{ errorMessage !== '' &&
  				<Message type="error" text={errorMessage}/>
  			}

		</div>
	);
}


// Prop Types
ChangePassword.propTypes = {
	auth0UserId: PropTypes.string.isRequired
}


export default ChangePassword;