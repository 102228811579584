

const DashboardScreen = () => {

	return (
		<>
			<h1>Welcome to the Tropic Admin Portal.</h1>

			<p>Right now, this portal will only allow you to view and update Ambassador's login details.</p>
  		</>
	)
}

export default DashboardScreen;