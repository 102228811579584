// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


// Assets
import './styles.css';


const TileIcon = props => {

	// Props
	const { label, url, icon, colour } = props;


	/**
	 * Get icon
	 */
	let iconStyle = {};

	if ( icon )
	{
		const iconUrl = require('./assets/icon-' + icon + '.svg').default;
		iconStyle.backgroundImage = "url('" + iconUrl + "')";
	}
	

	return (
		<Link to={url} className="TileIcon">
			<div className={`TileIcon__icon TileIcon__icon--colour-${colour}`} style={iconStyle}></div>
			<div className="TileIcon__label">{label}</div>
		</Link>
	);

}


TileIcon.propTypes = {
	label: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	colour: PropTypes.string.isRequired
};


TileIcon.defaultProps = {
	color: 'orange'
}

export default TileIcon;