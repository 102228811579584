// Packages
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';


import Api from 'other/Api';


// Assets
import './styles.css';


const ListAllUsers = props => {

	// State
	const [ users, setUsers ] = useState([]);
	const [ pageNumber, setPageNumber ] = useState(0);
	const [ meta, setMeta ] = useState({});


	const [ loading, setLoading ] = useState(false);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ errorMessage, setErrorMessage ] = useState('');
	


	const getUsers = async () => {

		setLoading(true);
		setErrorMessage('');

		const params = {
			page: pageNumber
		};

		if ( searchTerm !== '' )
		{
			if ( searchTerm.length < 3 )
			{
				setErrorMessage('Your search term must be 3 or more characters');
				setLoading(false);
				return false;
			}

			params.q = 'name:*' + searchTerm.replace(' ', '%20') + '* OR ';
			params.q += 'name:"' + searchTerm + '" OR ';
			params.q += 'email:"' + searchTerm + '" OR ';
			params.q += 'app_metadata.ambassador_id:"' + searchTerm + '"';
		}

		try
		{
			const response = await Api.auth0.users.list(params);

			setUsers(response.data.users);

			setMeta({
				total: response.data.total,
				length: response.data.length,
				start: response.data.start,
				limit: response.data.limit
			});
		}
		catch ( exception )
		{
			setErrorMessage(exception.response.error);
		}

		setLoading(false);
	}



	useEffect(() => {

		
			getUsers();

		// eslint-disable-next-line
	}, [pageNumber]);



	return (
		<>

		<form onSubmit={(e) => {
			e.preventDefault();
			getUsers();
		}}>

			<input style={{marginRight: '30px'}} type="text" onChange={(e) => {
				setSearchTerm(e.target.value);
				setPageNumber(0);
			}} className="ListUsers__search" placeholder="Search on name, Ambassador ID or email"/>



		</form>


		{ errorMessage !== '' &&
			<Message type="error" text={errorMessage}/>
		}


		{ loading &&
  			<Loading style={{marginTop: '25px'}} message="Loading users from Auth0"/>
  		}




  		{ ! loading && users.length &&
  			<>
  			<div className="table-caption">
  				Showing <b>{meta.start+1}</b> to <b>{meta.start+meta.length}</b> of <b>{meta.total}</b> Auth0 users
  			</div>
			
			<table className="table">
			<thead>
			<tr>
				<th>Name</th>
				<th>Ambassador ID</th>
				<th>Email</th>
				<th>Created</th>
				<th>Last Login</th>
			</tr>
			</thead>
			<tbody>
			
			{users.map((user) => {

				const ambassadorId = user.app_metadata ? user.app_metadata.ambassador_id : <span className="ghost">Not set</span>;

				return (
					<tr key={user.user_id}>
						<td><Link to={`/users/${user.user_id}`}><b>{user.name}</b></Link></td>
						<td>{ambassadorId}</td>
						<td>{user.email}</td>
						<td>{user.created_at !== '' ? dayjs(user.created_at).format('D MMMM, YYYY') : <span className="ghost">Never</span>}</td>
						<td>{user.last_login ? dayjs(user.last_login).format('D MMMM, YYYY') : <span className="ghost">Never</span>}</td>
					</tr>
				);

			})}

			</tbody>
			</table>
			</>
		}

		<div className="pagination">
			{ pageNumber > 0 &&
				<div onClick={() => {
					setPageNumber(pageNumber-1);
				}} className="pagination">
					&larr; Previous Page
				</div>
			}
			{ pageNumber === 0 &&
				<div></div>
			}

			{ meta.start + meta.length < meta.total &&
				<div onClick={() => {
					setPageNumber(pageNumber+1);
				}} className="pagination">
					Next Page &rarr;
				</div>
			}
		</div>

		</>

	);


}


export default ListAllUsers;