// Packages
import { Routes, Route } from "react-router-dom"


// Routes
import MiscRoutes from 'routes/misc';
import UserRoutes from 'routes/users';
import ReportRoutes from 'routes/reports';
import SettingRoutes from 'routes/settings';


const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/users/*" element={<UserRoutes/>}/>
			<Route path="/reports/*" element={<ReportRoutes/>}/>
			<Route path="/settings/*" element={<SettingRoutes/>}/>

			<Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	);
}


export default MainRoutes;