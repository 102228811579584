// Libraries
import PropTypes from 'prop-types';


// Components
import Avatar from 'components/Avatar';


// Assets
import './styles.css';




const UserProfileScreen = props => {

	// Props
	const { user, logout } = props;


	return (
		<div className="UserProfileScreen">

			<div className="UserProfileScreen__avatar">
				<Avatar showName={false} size="large" firstname={user.given_name} lastname={user.family_name} picture={user['https://id.tropicskincare.com/picture_url']}/>
			</div>

			<div className="UserProfileScreen__title UserProfileScreen__title--large">
				{user.given_name} {user.family_name}
			</div>

			<br/>

	

			<button onClick={() => {
				logout({ returnTo: window.location.origin })
			}} className="UserProfileScreen__action">Sign Out</button>

		</div>
	);
	
}


UserProfileScreen.propTypes = {
	user: PropTypes.object.isRequired
}


export default UserProfileScreen;