// Packages
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';


// Components
import Loading from 'components/UI/Loading';


import Api from 'other/Api';



const UserLog = props => {

	// Props
	const { auth0UserId } = props;


	// State
	const [ log, setLog ] = useState([]);
	const [ loading, setLoading ] = useState(false);



	const getLog = async () => {

		setLoading(true);
		//setErrorMessage('');

		try
		{
			const response = await Api.auth0.users.log(auth0UserId);

			console.log(response);
			setLog(response.data);
		}
		catch ( exception )
		{
			//setErrorMessage(exception.response.data.message);
		}

		setLoading(false);
	}




	useEffect(() => {

			getLog();

		// eslint-disable-next-line
	}, []);



	return (
		<>

		{ loading &&
  			<Loading message="Loading, please wait"/>
  		}

		{ ! loading && log.length > 0 &&
			<table className="table">
			<thead>
			<tr>
				<th>Date</th>
				<th>Type</th>
				<th>Client</th>
				<th>Description</th>
			</tr>
			</thead>
			<tbody>
			
			{log.map((entry) => {

				return (
					<tr key={entry.log_id}>
						<td>{dayjs(entry.date).format('D MMMM YYYY [at] HH:mm')}</td>
						<td>{entry.type_description}</td>
						<td>{entry.client_name}</td>
						<td>{entry.description}</td>
					</tr>
				);


			})}

			</tbody>
			</table>
		}

		</>

	);


}


export default UserLog;