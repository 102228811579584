// Libraries
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


// Assets
import './assets/styles.css';


const Accordian = props => {


	// Props
	const { title, open, icon, tabs } = props;


	// State
	const [bodyOpen, setBodyOpen] = useState(open);


	/**
	 * Get icon
	 */
	let iconStyle = {};

	if ( icon )
	{
		const iconUrl = require('./assets/icon-' + icon + '.svg').default;
		iconStyle.backgroundImage = "url('" + iconUrl + "')";
	}

	return (
		<div className={`Accordian Accordian--open-${bodyOpen}`}>
			<div onClick={() => {
				setBodyOpen(!bodyOpen)
			}} className="Accordian__header">
				<div className="Accordian__header__icon" style={iconStyle}/>
				<div className="Accordian__header__title">
					{title}
				</div>
				<div className="Accordian__header__handle"></div>
			</div>


			{ bodyOpen &&

				<>

				{ tabs &&
				<div className="Accordian__tabs">
					<ul className="Accordian__tabs__list">
					{tabs.map((tab) => {

						return (
							<li key={tab.url} className={`Accordian__tabs__list__item ${tab.active ? 'Accordian__tabs__list__item--active' : ''}`}>
								<Link to={tab.url} className="Accordian__tabs__list__item__link">{tab.label}</Link>
							</li>
						);

					})}
					</ul>
				</div>
				}

				<div className="Accordian__body">
					{props.children}
				</div>

				</>
			}

		</div>
	);

}


// Prop Types
Accordian.propTypes = {
	title: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	tabs: PropTypes.array
}


// Default Props
Accordian.defaultProps = {
	open: false
}


export default Accordian;