// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import ListUsersByRole from 'components/Auth0/ListUsersByRole';
import Accordian from 'components/UI/Accordian';
import Button from 'components/UI/Button';


const Screen = () => {

	const TABS = [
		{ label: 'All Users', url: '/users'},
		{ label: 'Staff Users', url: '/users/staff'},
		{ label: 'Admin Users', url: '/users/admin', active: true}
	];

	return (
		<>
		<ScreenTitle title="Auth0 Users"/>
 		
  		<Accordian icon="user" title="Auth0 Users" open={true} tabs={TABS}>
  			<ListUsersByRole roleName="admin"/>
  		</Accordian>

  		<Accordian icon="user" title="Create Tropic HQ User Account">
  			<p>Click on the following button to create an <b>admin user</b>. 
  				These are Tropic HQ team members and have access to a variety 
  				of online services used to run the business.</p>
  			<Button url="/users/create" label="Create Tropic HQ User"/>
  		</Accordian>
  		
  		</>
	)
}

export default Screen;