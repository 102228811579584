
// Components
import Navigation from 'components/Navigation';


// Assets
import './styles.css';


const Sidebar = () => {

	return (
		<div className="Sidebar">

			<div className="Sidebar__logo"></div>

			<Navigation/>

		</div>
	);
}

export default Sidebar;