// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import CreateUser from 'components/Auth0/CreateUser';


const UserCreateScreen = () => {

	
	

	return (
		<>

		<ScreenTitle title="Create Admin User" breadcrumb={[
			{label: 'Users', url: '/users'}
		]}/>

		<p>Use this form to create a <b>Tropic admin user</b> who will have access to all Tropic software.</p>

		<p className="colour--red"><b>DO NOT</b> use this form to create an Ambassador account - this should be done in FourFront only.</p>

		<CreateUser/>

  		</>
	)
}

export default UserCreateScreen