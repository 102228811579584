
// Assets
import './styles.css';

const StatBlock = props => {
	
	const { header, value, footer } = props;

	return (
		<div className="StatBlock">
			<div className="StatBlock__header">{header}</div>
			<div className="StatBlock__value">{value}</div>
			<div className="StatBlock__footer">{footer}</div>
		</div>
	);

}

export default StatBlock;