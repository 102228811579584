// Libraries
import { Routes, Route } from "react-router-dom"


// Screens
import DashboardScreen from 'screens/Dashboard'


// Layouts
import DefaultLayout from 'layouts/Default'


const MiscRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<DashboardScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default MiscRoutes