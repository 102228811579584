// Libraries
import PropTypes from 'prop-types';
import { useState } from 'react';


// Assets
import './styles.css'
import upArrow from './up-arrow.svg';

// Config
import config from 'config'


const EnvironmentSelector = props =>
{
	// Props
	const { showInProduction } = props;


	// State
	const [ drawer, setDrawer ] = useState(false);


	/**	
	 * Setup environments
	 */
	const ENVS = [
		{
			name: 'development',
			title: 'Development',
			description: 'Used to test cutting-edge features by the software team.',
			url: config.ENVS.DEV,
			colour: 'green'
		},
		{
			name: 'staging',
			title: 'Staging',
			description: 'Features available to Tropic to test before being released to the wider team.',
			url: config.ENVS.STAGE,
			colour: 'orange'
		},
		{
			name: 'production',
			title: 'Production',
			description: 'Live system used by Tropic HQ team.',
			url: config.ENVS.PROD,
			colour: 'red'
		}
	];


	/**
	 * 
	 */
	var currentEnv = 'development';

 	for ( var index in ENVS )
	{
		if ( ENVS[index].name === config.STAGE )
		{
			currentEnv = ENVS[index];
		}
	}


	if ( config.STAGE === 'production' && false === showInProduction )
	{
		return (
			<>
			</>
		);
	}
	else
	{
		return (
			<div className="EnvironmentSelector">
				{ true === drawer &&
				<div className="EnvironmentSelector__items">
					{ENVS.map((env) => {
						return (
							<a key={env.name} href={env.url} className="EnvironmentSelector__items__item">
								<div className="EnvironmentSelector__items__item__header">
									<div className={`EnvironmentSelector__items__item__light EnvironmentSelector__items__item__light--${env.colour}`}/>
								</div>
								<div className="EnvironmentSelector__items__item__body">
									<div className="EnvironmentSelector__items__item__title">
										{env.title}
									</div>
									<div className="EnvironmentSelector__items__item__description">
										{env.description}
									</div>
								</div>
							</a>
						);
					})}
				</div>
				}
				<div onClick={() => {
					setDrawer(!drawer);
				}} className="EnvironmentSelector__current">
					<div className="EnvironmentSelector__items__item">
						<div className="EnvironmentSelector__items__item__header">
							<div className={`EnvironmentSelector__items__item__light EnvironmentSelector__items__item__light--${currentEnv.colour}`}/>
						</div>
						<div className="EnvironmentSelector__items__item__body">
							<div className="EnvironmentSelector__items__item__title">
								{currentEnv.title}
							</div>
						</div>
						<div className="EnvironmentSelector__items__item__footer">
							<img src={upArrow} alt="Toggle"/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


EnvironmentSelector.propTypes = {
	showInProduction: PropTypes.bool.isRequired
};

EnvironmentSelector.defaultProps = {
	showInProduction: false
};

export default EnvironmentSelector;