// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Accordian from 'components/UI/Accordian';
import TileIcon from 'components/UI/TileIcon';


const ReportsScreen = () => {
	
	return (
		<>
		<ScreenTitle title="Reports"/>

		<Accordian icon="integration" title="Integration Reports" open={true}>

			<TileIcon 
				icon="auth0" 
				label="Auth0 Activity" 
				colour="blue" 
				url="/reports/auth0"
			/>

  		</Accordian>
  		</>
	);
}


export default ReportsScreen;