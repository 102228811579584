// Libraries
import { useState } from 'react';



// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Message from 'components/UI/Message';

import Api from 'other/Api';


// Assets
import './styles.css';


const CreateUser = props => {

	// State
	const [ name, setName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState('');
	const [ successMessage, setSuccessMessage ] = useState('');



	const onSubmit = async () => {

		if ( name === '' ) 
		{
			setErrorMessage('Enter a name above');
			return false;
		}

		if ( email === '' ) 
		{
			setErrorMessage('Enter an email above');
			return false;
		}

		setLoading(true);
		setSuccessMessage('');
		setErrorMessage('');

		try
		{
			await Api.auth0.users.create(name, email);

			setSuccessMessage('User created and invitation sent');
			setName('');
			setEmail('');
		}
		catch ( exception )
		{
			setErrorMessage(exception.data.error);
		}

		setLoading(false);
	}


	return (
		<div className="CreateUser">

			{ loading &&
				<Loading message="Create user, please wait..."/>
			}


			{ ! loading &&

				<form onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}} className="form">

					<div className="form__body">

						<div className="form__field">
							<input onChange={(e) => {
								setName(e.target.value);
							}} type="text" value={name} class="form__field__input" placeholder="Full Name"/>
						</div>

						<div className="form__field">
							<input onChange={(e) => {
								setEmail(e.target.value);
							}} type="text" value={email} class="form__field__input" placeholder="Email address"/>
						</div>

					</div>

					<div className="form__footer">
						<Button label="Create User"/>
					</div>  		
  				</form>

			}


  			{ successMessage !== '' &&
  				<Message type="ok" text={successMessage}/>
  			}


  			{ errorMessage !== '' &&
  				<Message type="error" text={errorMessage}/>
  			}

		</div>
	);
}


export default CreateUser;