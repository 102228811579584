// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import SettingsScreen from 'screens/Settings';
import AppStatusScreen from 'screens/Settings/Tropic-Software/Status';


// Layouts
import DefaultLayout from 'layouts/Default';


const SettingsRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<SettingsScreen/>}/>

				<Route exact path="/tropic-software/status" element={<AppStatusScreen/>}/>
			</Routes>
		</DefaultLayout>
	);
}


export default SettingsRoutes;