// Libraries
import { Link } from 'react-router-dom';


// Assets
import './styles.css';


const Navigation = () => {

	const ITEMS = [
		{
			enabled: true,
			label: 'Dashboard',
			icon: 'nav-dashboard.svg',
			url: '/'
		},
		{
			label: 'Orders',
			icon: 'nav-orders.svg',
			url: '/orders'
		},
		{
			enabled: true,
			label: 'Users',
			icon: 'nav-users.svg',
			url: '/users'
		},
		{
			enabled: true,
			label: 'Reports',
			icon: 'nav-chart.svg',
			url: '/reports'
		},
		{
			label: 'Online Content',
			icon: 'nav-website.svg',
			url: '/content'
		},
		{
			enabled: true,
			label: 'Settings',
			icon: 'nav-cog.svg',
			url: '/settings'
		}
	];

	return (
		<div className="Navigation">

			<ul className="Navigation__list">

			{ITEMS.map((item) => {

				if ( ! item.enabled )
				{
					return ('');
				}

				var style = {
					backgroundImage: "url('" + require('assets/' + item.icon).default + "')"
				};

				return (
					<li key={item.label} className="Navigation__list__item">
						<Link to={item.url} className="Navigation__list__item__link" style={style}></Link>
					</li>
				);

			})} 

			</ul>

		</div>
	);
}

export default Navigation;