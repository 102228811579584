// Packages
import axios from 'axios';


// Config
import Config from 'config';


const getAccessToken = async () => {

	return sessionStorage.getItem('access-token');
}



const init = async () => {
	const token = await getAccessToken();
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

const getAuth0Users = async (params = {}) => {

	await init();

	const response = await axios.get(Config.API_URL + '/auth0/users', {
		params: params
	});

	return response.data;
}

const getAuth0User = async (userId) => {

	await init();

	let id = userId.replace('|', '%7C');

	const response = await axios.get(Config.API_URL + '/auth0/users/' + id);

	return response.data;
}

const sendWelcomeEmail = async (userId, email) => {

	await init();

	let id = userId.replace('|', '%7C');

	try
	{
		const response = await axios.post(Config.API_URL + '/auth0/users/' + id + '/send-welcome-email', {
			email: email
		});

		return response.data;
	}
	catch ( exception )
	{
		throw exception.response;
	}
}




const createAuth0User = async (name, email) => {

	await init();

	try
	{
		const response = await axios.post(Config.API_URL + '/auth0/users', {
			name: name,
			email: email
		});

		return response.data;
	}
	catch ( exception )
	{
		throw exception.response;
	}
}



const setPassword = async (userId, password) => {

	await init();

	let id = userId.replace('|', '%7C');

	try
	{
		return await axios.post(Config.API_URL + '/auth0/users/' + id + '/set-password', {
			password: password
		});
	}
	catch ( exception )
	{
		throw exception.response;
	}
}



const getAuth0UserLog = async (userId) => {

	await init();

	let id = userId.replace('|', '%7C');

	const response = await axios.get(Config.API_URL + '/auth0/users/' + id + '/log');

	return response.data;
}


const getAuth0UserRoles = async (userId) => {

	await init();

	let id = userId.replace('|', '%7C');

	const response = await axios.get(Config.API_URL + '/auth0/users/' + id + '/roles');

	return response.data;
}



const getAuth0Stats = async (params = {}) => {

	await init();

	const response = await axios.get(Config.API_URL + '/auth0/stats');

	return response.data;
}


const getUsersInRole = async (roleName, params = {}) => {

	await init();

	const response = await axios.get(Config.API_URL + '/auth0/roles/' + roleName + '/users', {
		params: params
	});

	return response.data;
}


const getAppStatus = async (appName) => {

	await init();

	const response = await axios.get(Config.API_URL + '/appstatus/' + appName);

	return response.data;
}



const setAppStatus = async (appName, enabled, message) => {

	await init();

	const response = await axios.post(Config.API_URL + '/appstatus/' + appName, {
		enabled: enabled,
		message: message
	});

	return response.data;
}




const getAppServiceStatus = async () => {

	await init();

	try
	{
		const response = await axios.get(Config.APPSTATUS_URL);

		return response.data;
	}
	catch ( exception )
	{
		throw exception.response;
	}

}


const output = {
	apps: {
		getStatus: getAppStatus,
		setStatus: setAppStatus,
		getServiceStatus: getAppServiceStatus // This one reads directly from S3
	},
	auth0: {
		stats: getAuth0Stats,
		users: {
			list: getAuth0Users,
			get: getAuth0User,
			create: createAuth0User,
			sendWelcomeEmail,
			setPassword,
			log: getAuth0UserLog,
			roles: getAuth0UserRoles
		},
		roles: {
			getUsers: getUsersInRole
		}
	}
};

export default output;
