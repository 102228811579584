// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import ListAllUsers from 'components/Auth0/ListAllUsers';
import Accordian from 'components/UI/Accordian';


const UsersScreen = () => {

	const TABS = [
		{ label: 'All Users', url: '/users', active: true},
		{ label: 'Staff Users', url: '/users/staff'},
		{ label: 'Admin Users', url: '/users/admin'}
	];

	return (
		<>
		<ScreenTitle title="Auth0 Users"/>
 		
  		<Accordian icon="user" title="Auth0 Users" open={true} tabs={TABS}>
  			<ListAllUsers/>
  		</Accordian>

  		</>
	)
}

export default UsersScreen;