// Libraries
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import SendWelcomeEmail from 'components/Auth0/SendWelcomeEmail';
import ChangePassword from 'components/Auth0/ChangePassword';
import Accordian from 'components/UI/Accordian';
import Loading from 'components/UI/Loading';
import ScreenTitle from 'components/UI/ScreenTitle';
import InfoTable from 'components/UI/InfoTable';
import UserLog from 'components/Auth0/UserLog';


// Other
import Api from 'other/Api';
import Help from 'other/Help';


const UserViewScreen = () => {

	// URL Parameters
	const { userId } = useParams();


	// State
	const [ user, setUser ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	const [ roles, setRoles ] = useState(null);
	const [ rolesLoading, setRolesLoading ] = useState(false);



	useEffect(() => {

		setLoading(true);
		setRolesLoading(true);

		const getUser = async (userId) => {

			const response = await Api.auth0.users.get(userId);

			setUser(response.data);
			setLoading(false);
		}

		getUser(userId);

		const getRoles = async (userId) => {

			const response = await Api.auth0.users.roles(userId);

			setRoles(response.data);
			setRolesLoading(false);
		}

		getRoles(userId);
	

	}, [userId]);



	/**
	 * User Roles
	 */
	let userRoles = '';

	if ( rolesLoading === true )
	{
		userRoles = 'Loading...';
	}
	else if ( roles !== null )
	{
		for ( var i = 0; i < roles.length; i++ )
		{
			userRoles += roles[i].name + ' ';
		}
	}




	return (
		<>
  		
  		{ loading &&
  			<Loading message="Loading, please wait"/>
  		}


  		{ ! loading && user !== null &&

  			<>

  			<ScreenTitle title={user.name}/>

  			<Accordian title="Overview" icon="user" open={true}>
  				
  				<InfoTable.Table>

  					<InfoTable.Row>
  						<InfoTable.Column label="Name" value={user.name}/>
  						<InfoTable.Column label="Email" value={user.email}/>
  					</InfoTable.Row>

  					<InfoTable.Row>
  						<InfoTable.Column label="Created" value={dayjs(user.created_at).format('D MMMM YYYY [at] HH:mm')} help={Help.AUTH0_CREATED_DATE}/>
  						<InfoTable.Column label="Auth0 User ID" value={user.user_id} help={Help.AUTH0_USER_ID}/>
  					</InfoTable.Row>

  					<InfoTable.Row>
  						<InfoTable.Column label="Last Login" value={dayjs(user.last_login).format('D MMMM YYYY [at] HH:mm')} emptyValue="Never logged in"/>
  						<InfoTable.Column label="Last IP" value={user.last_ip} emptyValue="Not available" help={Help.AUTH0_LAST_IP}/>
  					</InfoTable.Row>

  					<InfoTable.Row>
  						<InfoTable.Column label="Number of Logins" value={user.logins_count} emptyValue="Never logged in"/>
  						<InfoTable.Column label="Last Password Reset" value={dayjs(user.last_password_reset).format('D MMMM YYYY [at] HH:mm')}/>
  					</InfoTable.Row>

  					<InfoTable.Row>
  						<InfoTable.Column label="User Roles" value={userRoles} emptyValue="None" help={Help.AUTH0_USER_ROLES}/>
  						<InfoTable.Column label="" value="" emptyValue=""/>
  					</InfoTable.Row>

  				</InfoTable.Table>
 			
  			</Accordian>


  			<Accordian title="Ambassador Detail" icon="detail">

  				<p>This information is stored in Auth0 in the user app_metadata. It is written by FourFront and shouldn't be modified manually.</p>

  				<InfoTable.Table>

  					<InfoTable.Row>
  						<InfoTable.Column label="Ambassador ID" value={user.app_metadata ? user.app_metadata.ambassador_id : '-'}/>
  						<InfoTable.Column label="Title" value={user.app_metadata ? user.app_metadata.title : '-'}/>
  					</InfoTable.Row>

  					<InfoTable.Row>
  						<InfoTable.Column label="Manager ID" value={user.app_metadata ? user.app_metadata.manager_id : '-'}/>
  						<InfoTable.Column label="Sponsor ID" value={user.app_metadata ? user.app_metadata.sponsor_id : '-'}/>
  					</InfoTable.Row>

  				</InfoTable.Table>
	  	
	  		</Accordian>


	  		<Accordian title="Change User's Password" icon="padlock">
	  			
	  			<p>This feature allows you to change the Ambassador's password. 
	  			They will receive an email notification that their password has been 
	  			changed, but the email <u>won't</u> include their password. 
	  			So you'll need to use another way of letting them know. 
	  			It's good practice to encourage the Ambassador to change their password 
	  			when they next login through the Ambassador Access app.</p>

	  			<p>Use the 'Suggest Password' link to generate an easy-to-understand 
	  			password.</p>

	  			<ChangePassword auth0UserId={user.user_id}/>

	  		</Accordian>


	  		<Accordian title="Resend 'Ambassador Access' Email" icon="email">

	  			<p>You can resend the initial Ambassador Access email sent to an Ambassador when they were first created in the SSO (single sign on) system. 
	  			This includes a link will allows them to set (or change) their own password. If the Ambassador is having problems with their primary email account, then you can enter an alternative email address in the box below.</p>

	  			<SendWelcomeEmail auth0UserId={user.user_id} email={user.email}/>

	  		</Accordian>



	  		<Accordian title="Auth0 Logs" icon="log">

	  			<p>These are log entries directly from Auth0 for this user. If you wish to know more about these messages, then <a href="https://auth0.com/docs/monitor-auth0/logs/log-event-type-codes" target="_blank" rel="noreferrer">click here</a> for the full explanations.</p>

	  			<UserLog auth0UserId={user.user_id}/>

	  		</Accordian>



  			</>
  		}

  		
  		</>
	)
}

export default UserViewScreen